import React from "react";

import Base from "../layouts/Base";
import Head from "../components/Head";
import HomePage from "../components/Home";
import { HOST_URL, LOGO_URL, DEFAULT_IMG_URL } from "config/constants";

export default function Home(props) {
  return (
    <Base {...props}>
      <Head>
        <title>Home - Real America's Voice News</title>
        <meta
          name="description"
          content="Real America's Voice News is your one stop news and entertainment network."
        />
        <meta name="og:title" content="Home - Real America's Voice News" />
        <meta
          name="og:description"
          content="Real America's Voice News is your one stop news and entertainment network."
        />
        <link rel="canonical" href={`${HOST_URL}/`} />
        <link
          rel="preload"
          as="image"
          href={`${LOGO_URL}@138x70`}
          imagesrcset={`${LOGO_URL}@276x140 2x`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={DEFAULT_IMG_URL} />
      </Head>
      <main>
        <HomePage {...props} />
      </main>
    </Base>
  );
}

export async function getServerSideProps() {
  const apiPrivateUrl = process.env.apiPrivateUrl;

  try {
    const apiEndpoints = [
      `${apiPrivateUrl}/widgets/home`,
      `${apiPrivateUrl}/menu/header`,
      `${apiPrivateUrl}/menu/sidebar`,
      `${apiPrivateUrl}/menu/footer`,
    ];

    const [widgets, headerMenu, sidebarMenu, footerMenu] = await Promise.all(
      apiEndpoints.map((endpoint) =>
        fetch(endpoint)
          .then((response) => response.json())
          .catch((error) => console.error(error))
      )
    );

    if (!widgets) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        widgets,
        headerMenu,
        sidebarMenu,
        footerMenu,
        headerType: "homeHeader",
      },
    };
  } catch (e) {
    console.group("pages/index.jsx - getServerSideProps");
    console.log(e);
    console.groupEnd();

    return {
      notFound: true,
    };
  }
}
