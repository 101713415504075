import React from "react";
import dynamic from "next/dynamic";

// import { ContentTop } from "./ContentTop";

const ContentWidgetsSection = dynamic(
  () => import("../widgets/content/Index"),
  { ssr: false }
);
const ContentTop = dynamic(() => import("./ContentTop"), { ssr: false });
const MainContent = dynamic(() => import("./MainContent"), { ssr: false });
const Information = dynamic(() => import("./Sections/Information"), {
  ssr: false,
});

export default function HomePage({ widgets }) {
  return (
    <>
      <ContentWidgetsSection widgets={widgets?.["top-full-width"]} />

      <ContentTop widgets={widgets} />
      <MainContent widgets={widgets} />

      <Information />
      <ContentWidgetsSection widgets={widgets?.["bottom-full-width"]} />
    </>
  );
}
